<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
        d="M6 0L7.85239 3.9525L12 4.58909L9 7.65798L9.70479 12L6 9.94774L2.29063 12L3 7.65798L0 4.58909L4.14308 3.9525L6 0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarIcon'
}
</script>
