<script setup>
import StarIcon from "~/components/icons/StarIcon.vue";
import GoogleIcon from "~/components/icons/GoogleIcon.vue";
import TrustpilotIcon from "~/components/icons/TrustpilotIcon.vue";
import TrustpilotStarIcon from "~/components/icons/TrustpilotStarIcon.vue";
import CheckIcon from "~/components/icons/CheckIcon.vue";
import {useStartupStore} from "~/store/sturtupStore";

const startupStore = useStartupStore();
</script>

<template>
  <div class="top-bar grey-bc">
    <div class="container">
      <div class="row full-width">
        <div class="row">
          <div class="options row g-24">
            <div class="option row g-8 check">
              <CheckIcon/>
              <span v-if="startupStore.campaign">
                <router-link :to="startupStore.campaign.top_url">{{ startupStore.campaign.top_text }}</router-link>
              </span>
              <span v-else>
                <router-link :to="$t('header.options.0.url')">{{ $t('header.options.0.name') }}</router-link>
              </span>
            </div>
            <div class="option row hidden-smd g-8 check">
              <CheckIcon/>
              <span>{{ $t('header.options.1.name') }}</span>
            </div>
            <div class="option row hidden-smd g-8 check">
              <CheckIcon/>
              <span>{{ $t('header.options.2.name') }}</span>
            </div>
          </div>
        </div>

        <div class="row right-options g-32">
          <div class="options row g-8">
            <div class="option row g-4">
              <span>4.8</span>
              <StarIcon height="14px" fill="#fcbf00"/>
            </div>
            <div class="option row g-4" v-if="!$device.isMobile">
              <GoogleIcon height="14px"/>
              <span class="hidden-smd">{{ $t('rating.google_reviews') }}</span>
            </div>
          </div>
          <div class="options row g-8">
            <div class="option row g-4">
              <span>4.6</span>
              <TrustpilotStarIcon height="14px"/>
            </div>
            <div class="option row g-4" v-if="!$device.isMobile">
              <TrustpilotIcon height="14px"/>
              <span class="hidden-smd">{{ $t('rating.trustpilot') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/variables.scss";

.container {
  justify-content: center;
}

.top-bar {
  height: 32px;

  @include mobile {
    height: 24px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
  }
}

.right-options {
  margin-left: auto;
}

.option {
  display: flex;
  align-items: center;
  font-size: 12px;

  svg {
    height: 14px;
  }

  &.check {
    svg {
      stroke: $black;
    }
  }
}
</style>
