<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
    <path
        d="M0 2.8C0 1.2536 1.28453 0 2.86908 0H12.9108C14.4954 0 15.7799 1.2536 15.7799 2.8V11.2C15.7799 12.7464 14.4954 14 12.9108 14H2.86908C1.28453 14 0 12.7464 0 11.2V2.8Z"
        fill="white"/>
    <path
        d="M15.2969 9.80007H18.5195C18.9347 9.80007 19.1526 9.31927 18.8734 9.01949L17.2096 7.23341L15.2969 4.20007V9.80007Z"
        fill="white"/>
    <path
        d="M12.3408 6.36487H11.9749V6.34647H7.88645V8.1198H10.4538C10.0792 9.15211 9.07278 9.89314 7.88645 9.89314C6.38123 9.89314 5.16083 8.70212 5.16083 7.23314C5.16083 5.76415 6.38123 4.57314 7.88645 4.57314C8.58126 4.57314 9.21338 4.82894 9.69468 5.24678L10.9796 3.99281C10.1683 3.25489 9.083 2.7998 7.88645 2.7998C5.37775 2.7998 3.34375 4.78483 3.34375 7.23314C3.34375 9.68145 5.37775 11.6665 7.88645 11.6665C10.3952 11.6665 12.4292 9.68145 12.4292 7.23314C12.4292 6.93588 12.3978 6.64572 12.3408 6.36487Z"
        fill="#FFC107"/>
    <path
        d="M3.87109 5.16952L5.3636 6.23773C5.76745 5.26196 6.74549 4.57302 7.89002 4.57302C8.58483 4.57302 9.21695 4.82882 9.69825 5.24666L10.9832 3.99269C10.1718 3.25476 9.08657 2.79968 7.89002 2.79968C6.14517 2.79968 4.632 3.76105 3.87109 5.16952Z"
        fill="#FF3D00"/>
    <path
        d="M7.88789 11.6663C9.06127 11.6663 10.1274 11.2281 10.9335 10.5154L9.52758 9.3543C9.07149 9.69146 8.50457 9.89295 7.88789 9.89295C6.70634 9.89295 5.70308 9.15769 5.32513 8.13159L3.84375 9.24547C4.59557 10.6812 6.12237 11.6663 7.88789 11.6663Z"
        fill="#4CAF50"/>
    <path
        d="M12.3333 6.36447H11.9673V6.34607H7.87891V8.1194H10.4462C10.2663 8.61527 9.93948 9.04287 9.51791 9.35431L9.5186 9.35386L10.9246 10.515C10.8251 10.6032 12.4216 9.4494 12.4216 7.23274C12.4216 6.93548 12.3903 6.64532 12.3333 6.36447Z"
        fill="#1976D2"/>
  </svg>
</template>

<script>
export default {
  name: 'GoogleIcon'
}
</script>
