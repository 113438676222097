<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
    <path
        d="M14.9961 4.96719H9.64983L7.99801 0L6.34241 4.96719H0.996094L5.32296 8.03658L3.66731 13L7.99418 9.93062L12.3172 13L10.6654 8.03658L14.9884 4.96719H14.9961Z"
        fill="#00B67A"/>
    <path d="M11.0391 9.15957L10.6673 8.03662L7.99609 9.93066L11.0391 9.15957Z" fill="#005128"/>
  </svg>
</template>

<script>
export default {
  name: 'TrustpilotIcon'
}
</script>
