<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M0.857422 12H23.1431" stroke="#0C112D" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.857422 4.28571H23.1431" stroke="#0C112D" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.857422 19.7143H23.1431" stroke="#0C112D" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BurgerIcon'
}
</script>
