<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M12 0H0V12H12V0Z" fill="#00B67A"/>
    <path
        d="M5.9979 8.09046L7.82368 7.62815L8.58751 9.97656L6.00121 8.09046H5.9979ZM10.1989 5.05193H6.98616L5.9979 2.03015L5.0096 5.05193H1.79688L4.39655 6.92797L3.40825 9.95311L6.00792 8.07705L7.60927 6.92797L10.1955 5.05193H10.1989Z"
        fill="#FCFBF3"/>
  </svg>
</template>

<script>
export default {
  name: 'TrustpilotStarIcon'
}
</script>
